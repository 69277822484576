import { Select, Form, Button } from "antd";
import NProgress from "nprogress";
import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import OrderReport from "./OrderReport";
import loGet from "lodash/get";
import { coreAPI } from "../../utils/request";
import { message } from "antd";

import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const { Option } = Select;
export default function Report() {
  const [dateType, setdateType] = useState("month");
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [loading, setloading] = useState(false);

  const [orders, setorders] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    NProgress.start();
    setloading(true);
    try {
      const { data } = await coreAPI.get(`/admin/orders/report`, {
        params: {
          type: dateType,
          startDate: startDate
            ? moment(moment(startDate).startOf("day")).toISOString()
            : null,
          endDate: endDate
            ? moment(moment(endDate).endOf("day")).toISOString()
            : null,
        },
      });
      setorders(data);
    } catch (error) {
      message.error(loGet(error, "response.data.message"));
      console.log(error);
    }
    setloading(false);
    NProgress.done();
  };

  const onChangeDate = (date) => {
    if (date) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <div>
      <PageHeader title="ORDERS REPORTS" />

      <Form layout="vertical" onFinish={fetchData}>
        <div className="flex flex-wrap -mx-2 " style={{ maxWidth: 1000 }}>
          <div className="col px-2 w-full md:w-1/4">
            <Form.Item>
              <Select
                placeholder="Day"
                showSearch
                allowClear
                value={dateType}
                onChange={(option) => setdateType(option)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  { value: "day", title: "Day" },
                  { value: "month", title: "Month" },
                  { value: "year", title: "Year" },
                ].map((type) => (
                  <Option key={type?.value} value={type?.value}>
                    {type?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col px-2 w-full md:w-2/4">
            <Form.Item>
              <RangePicker
                style={{ width: "100%" }}
                format="DD MMM YYYY"
                onChange={onChangeDate}
              />
            </Form.Item>
          </div>

          <div className="col px-2 w-full md:w-1/5">
            <Form.Item>
              <Button
                disabled={loading}
                className="w-full lg:w-auto"
                color="secondary"
                htmlType="submit"
                style={{ height: 32 }}
              >
                Search
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <div className="px-4 pb-6" style={{ overflowX: "auto" }}>
        <OrderReport orders={orders} />
      </div>
    </div>
  );
}
