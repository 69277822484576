import React from "react";
import { Router as ReachRouter } from "@reach/router";

import NotFoundPage from "./components/NotFoundPage";
import Test from "./container/Test";
import Dashboard from "./container/Dashboard";
import Orders from "./container/Orders";
import Processed from "./container/Processed";
import Auths from "./container/Auths";
import Login from "./container/Auths/Login";
import Tracking from "./container/Tracking";
import Report from "./container/Report";
import History from "./container/History";

const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
};
export default function Router() {
  return (
    <ReachRouter primary={false}>
      <ScrollToTop path="/">
        <Login path="/login" />
        <Auths path="/">
          <Dashboard path="/" />
          <Orders path="/orders" />
          <Processed path="/processeds" />
          <Report path="/reports" />
          <History path="/history" />

          <Test path="/test" />
          <NotFoundPage path="*" />
        </Auths>

        <Test path="/test2" />
        <Tracking path="/tracking/:id" />

        <NotFoundPage path="/404" />
        <NotFoundPage path="*" />
      </ScrollToTop>
    </ReachRouter>
  );
}
