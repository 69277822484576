import React, { useEffect, useState } from "react";
import { navigate, useParams } from "@reach/router";
import {
  Button,
  Descriptions,
  Drawer,
  message,
  Skeleton,
  Table,
  Tag,
  Input,
  Tooltip,
  Badge,
  Popconfirm,
  Form,
  Select,
} from "antd";
import loGet from "lodash/get";
import NProgress from "nprogress";
import moment from "moment";
import { coreAPI } from "../../utils/request";
import PageHeader from "../../components/PageHeader";
import {
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import OrderReport from "../Report/OrderReport";

const { Option } = Select;

const { Search } = Input;

const cardTypes = [
  "",
  "Thẻ cơ bản",
  "Thẻ đen cơ bản",
  "Thẻ RGB đen",
  "Thẻ RGB màu",
  "Thẻ nametag Luxury",
  "Thẻ CLDxMetap",
  "Thẻ Geometric",
];

export default function Tracking() {
  const [dateType, setdateType] = useState("day");
  const [dataResponese, setdataResponese] = useState(null);
  const [dataReport, setdataReport] = useState(null);
  const [filter, setFilter] = useState("");
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    NProgress.start();
    await fetchOrder();
    await fetchReport();
    NProgress.done();
  };

  const fetchOrder = async () => {
    try {
      const { data } = await coreAPI.get(`/tracking/ref/${id}`);
      setdataResponese(data);
    } catch (error) {
      message.error(loGet(error, "response.data.message"));
      console.log(error);
      navigate("/404");
    }
  };

  const fetchReport = async (type = "mday") => {
    try {
      const { data } = await coreAPI.get(`/tracking/ref/${id}/report`, {
        params: { type: type },
      });
      setdataReport(data);
    } catch (error) {
      message.error(loGet(error, "response.data.message"));
      console.log(error);
      navigate("/404");
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (text) => text + 1,
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "20%",
    },
    {
      title: "Loại thẻ",
      dataIndex: "typeCard",
      key: "typeCard",
      width: "20%",
      render: (text) => cardTypes[text],
    },
    {
      title: "Thời gian",
      dataIndex: "createAt",
      key: "date",
      render: (text) => moment(text).format("DD MMM YYYY"),
      width: "20%",
    },
  ];

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  const groupByCardType = dataResponese?.orders
    ? groupBy(dataResponese?.orders, "typeCard")
    : [];

  return (
    <div className="mt-8 text-black">
      <div className="px-4">
        <div className="text-2xl font-bold mb-4 uppercase">Chào {id}</div>
        <h1
          className="uppercase font-bold mb-0 text-base mb-4"
          style={{ color: "rgba(0, 0, 0, 0.85)" }}
        >
          THỐNG KÊ SỐ LƯỢNG THEO DÕI
        </h1>
        <div className="text-xl">
          <div className="flex">
            <div style={{ width: 200 }}>Số lượng click: </div>
            <div>{dataResponese?.clicksNum}</div>
          </div>
          <div className="flex">
            <div style={{ width: 200 }}>Số orders: </div>
            <div>{dataResponese?.ordersNum}</div>
          </div>
          <div className="flex">
            <div style={{ width: 200 }}>Đã paid: </div>
            <div>{dataResponese?.paindNum}</div>
          </div>
          <div style={{ width: 210 }} className="line my-4"></div>
          <div className="flex">
            <div>
              {Object.keys(groupByCardType).map((item) => {
                return (
                  <div className="flex">
                    <div style={{ width: 200 }}>{cardTypes[item]}: </div>
                    <div>{groupByCardType[item].length}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <h1 className="px-4">
        <PageHeader title="ORDERS ĐÃ PAID" />
      </h1>
      {dataResponese === null ? (
        <Skeleton size="large" width={600} active />
      ) : (
        <Table
          columns={columns}
          dataSource={dataResponese?.orders || []}
          scroll={{ x: 900 }}
        />
      )}
      <div className="px-4 pb-6" style={{ overflowX: "auto" }}>
        <PageHeader title="ORDERS REPORTS" />
        <Form layout="vertical" onFinish={fetchData}>
          <div className="flex flex-wrap -mx-2 " style={{ maxWidth: 1000 }}>
            <div className="col px-2 w-full md:w-1/4">
              <Form.Item>
                <Select
                  placeholder="Day"
                  showSearch
                  value={dateType}
                  onChange={async (option) => {
                    setdateType(option);
                    NProgress.start();
                    await fetchReport(option);
                    NProgress.done();
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[
                    { value: "day", title: "Day" },
                    { value: "month", title: "Month" },
                    { value: "year", title: "Year" },
                  ].map((type) => (
                    <Option key={type?.value} value={type?.value}>
                      {type?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </Form>
        {dataReport !== null && dataReport.length > 0 ? (
          <div className="mb-16">
            <OrderReport orders={dataReport || []} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
