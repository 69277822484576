import React, { useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Drawer,
  message,
  Skeleton,
  Table,
  Tag,
  Input,
  Tooltip,
  Badge,
  Popconfirm,
  Form,
  Select,
} from "antd";
import loGet from "lodash/get";
import NProgress from "nprogress";
import moment from "moment";
import { coreAPI } from "../../utils/request";
import PageHeader from "../../components/PageHeader";
import {
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import Axios from "axios";
import { DatePicker, Space } from "antd";

const { RangePicker } = DatePicker;

const { Search } = Input;
const cardTypes = [
  "",
  "Thẻ cơ bản",
  "Thẻ đen cơ bản",
  "Thẻ RGB đen",
  "Thẻ RGB màu",
  "Thẻ nametag Luxury",
  "Thẻ CLDxMetap",
  "Thẻ Geometric",
];
export default function Orders() {
  const [orders, setorders] = useState(null);
  const [filter, setFilter] = useState("");
  const [visible, setVisible] = useState(false);
  const [orderDetail, setOrderDetail] = useState("");

  const [endDate, setEndDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment().subtract(30, "d"));
  const [loading, setloading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    NProgress.start();
    setloading(true);
    setorders(null);
    try {
      const { data } = await coreAPI.get(`/admin/orders`, {
        // params: {
        //   startDate: startDate.unix(),
        //   endDate: endDate.unix(),
        // },
      });
      setorders(data);
    } catch (error) {
      message.error(loGet(error, "response.data.message"));
      console.log(error);
    }
    setloading(false);
    NProgress.done();
  };

  const orderPaid = async (order) => {
    NProgress.start();
    try {
      const { data } = await coreAPI.put(`/admin/orders/paid/` + order._id);
      message.success("Processed order successfull");
      const tmp = orders.map((f) => (f._id !== data._id ? f : data));
      setorders(tmp);
    } catch (error) {
      message.error(loGet(error, "response.data.message"));
      console.log(error);
    }
    NProgress.done();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Card Type",
      dataIndex: "typeCard",
      key: "typeCard",
      width: "20%",
      render: (text) => (
        <div style={{ wordBreak: "break-word" }}>{cardTypes[text]}</div>
      ),
    },
    {
      title: "Payment",
      dataIndex: "paid",
      key: "paid",
      render: (text) =>
        text ? (
          <Tag color="cyan">Paid</Tag>
        ) : (
          <Tag color="green">Not Paid yet</Tag>
        ),
    },
    {
      title: "Date",
      dataIndex: "createAt",
      key: "date",
      render: (text) => moment(text).format("HH:mm, DD MMM YYYY"),
    },
    {
      title: "Action",
      key: "Action",
      width: "15%",
      align: "center",
      // eslint-disable-next-line react/display-name
      render: (text, record) => (
        <>
          <Tooltip title="Paid" placement="bottom">
            <Popconfirm
              disabled={record.paid}
              title="Are you sure Paid?"
              onConfirm={(e) => {
                orderPaid(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={record.paid}
                icon={<CheckOutlined />}
                className="md:mr-2 mr-0"
                type="primary"
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="View Detail" placement="bottom">
            <Button
              icon={<EyeOutlined />}
              className="md:mr-2 mr-0"
              onClick={(e) => {
                e.preventDefault();
                setVisible(true);
                setOrderDetail(record);
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  let dataCource = [];
  if (orders !== null) {
    dataCource = orders
      .map((m) => {
        return { ...m, key: m?.id };
      })
      .filter((f) => {
        const filterName = String(f.name || "").toLowerCase();
        const filterEmail = String(f.email || "").toLowerCase();
        const filterID = String(f.id || "").toLowerCase();
        const filterCode = String(f?.card?.code || "").toLowerCase();

        return (
          filterCode.includes(filter.toLowerCase()) ||
          filterName.includes(filter.toLowerCase()) ||
          filterEmail.includes(filter.toLowerCase()) ||
          filterID.includes(filter.toLowerCase())
        );
      });
  }

  const onChangeDate = (date) => {
    if (date) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <div>
      <PageHeader
        title="ORDERS"
        extra={
          <Search
            placeholder="Filter"
            onChange={(event) => setFilter(event.target.value)}
          />
        }
      />

      {/* <Form layout="vertical" onFinish={fetchData}>
        <div className="flex flex-wrap -mx-2 " style={{ maxWidth: 1000 }}>
          <div className="col px-2 w-full md:w-2/4">
            <Form.Item>
              <RangePicker
                style={{ width: "100%" }}
                format="DD MMM YYYY"
                value={[startDate, endDate]}
                onChange={onChangeDate}
              />
            </Form.Item>
          </div>

          <div className="col px-2 w-full md:w-1/5">
            <Form.Item className="w-full">
              <Button
                loading={loading}
                className="w-full"
                color="secondary"
                htmlType="submit"
                style={{ height: 32 }}
              >
                Search
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form> */}

      {orders === null ? (
        <Skeleton size="large" width={600} active />
      ) : (
        <Table
          columns={columns}
          dataSource={dataCource}
          scroll={{ x: "100%" }}
        />
      )}

      <Drawer
        title={"Order Detail"}
        placement="right"
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible}
        width="60%"
      >
        {orderDetail !== null ? (
          <div>
            <Descriptions bordered column={{ md: 2, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Name">
                {orderDetail?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {orderDetail?.email}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                {orderDetail?.phone}
              </Descriptions.Item>

              <Descriptions.Item label="Status">
                {orderDetail?.paid ? (
                  <Tag color="cyan">Paid</Tag>
                ) : (
                  <Tag color="green">Not Paid yet</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Card Type">
                {cardTypes[orderDetail?.typeCard]}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {orderDetail?.address}
              </Descriptions.Item>
              {orderDetail?.nameTag ? (
                <Descriptions.Item label="Name Tag">
                  {orderDetail?.nameTag}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </div>
        ) : null}
      </Drawer>
    </div>
  );
}
