import React, { useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Drawer,
  message,
  Skeleton,
  Table,
  Tag,
  Input,
  Tooltip,
  Badge,
  Popconfirm,
} from "antd";
import loGet from "lodash/get";
import NProgress from "nprogress";
import moment from "moment";
import { coreAPI } from "../../utils/request";
import PageHeader from "../../components/PageHeader";
import {
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import Axios from "axios";

const { Search } = Input;

export default function Processed() {
  const [processeds, setprocesseds] = useState(null);
  const [filter, setFilter] = useState("");
  const [visible, setVisible] = useState(false);
  const [orderDetail, setOrderDetail] = useState("");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    NProgress.start();
    try {
      const { data } = await coreAPI.get(`/admin/process-order`);
      setprocesseds(data);
    } catch (error) {
      message.error(loGet(error, "response.data.message"));
      console.log(error);
    }
    NProgress.done();
  };

  function download(filename, data) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;base64," + data);
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const processedDownload = async (processed) => {
    NProgress.start();
    try {
      const token = window.localStorage.getItem("token");
      Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const fileResponse = await Axios.get(
        process.env.REACT_APP_CORE_API +
          `/admin/process-order/download/` +
          processed._id
      );

      download(
        fileResponse.request.getResponseHeader("Content-Disposition"),
        fileResponse.data
      );
    } catch (error) {
      message.error(loGet(error, "response.data.message"));
      console.log(error);
    }
    NProgress.done();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Date",
      dataIndex: "createAt",
      key: "date",
      render: (text) => moment(text).format("HH:mm, DD MMM YYYY"),
    },
    {
      title: "Action",
      key: "Action",
      align: "center",
      // eslint-disable-next-line react/display-name
      render: (text, record) => (
        <Tooltip title="Download" placement="bottom">
          <Button
            onClick={() => processedDownload(record)}
            icon={<CheckOutlined />}
            className="md:mr-2 mr-0"
            type="primary"
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title="PROCESSEDS"
        extra={
          <Search
            placeholder="Filter"
            onChange={(event) => setFilter(event.target.value)}
          />
        }
      />
      {processeds === null ? (
        <Skeleton size="large" width={600} active />
      ) : (
        <Table
          columns={columns}
          dataSource={processeds}
          scroll={{ x: "100%" }}
        />
      )}
    </div>
  );
}
