import React, { useEffect, useState } from "react";
import { message, Skeleton, Table } from "antd";
import loGet from "lodash/get";
import NProgress from "nprogress";
import moment from "moment";
import { coreAPI } from "../../utils/request";
import PageHeader from "../../components/PageHeader";

const cardTypes = [
  "",
  "Thẻ cơ bản",
  "Thẻ đen cơ bản",
  "Thẻ RGB đen",
  "Thẻ RGB màu",
  "Thẻ nametag Luxury",
  "Thẻ CLDxMetap",
  "Thẻ Geometric",
];

export default function History() {
  const [orders, setorders] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    NProgress.start();
    try {
      const { data } = await coreAPI.get(`/admin/orders/history`);
      setorders(data);
    } catch (error) {
      message.error(loGet(error, "response.data.message"));
      console.log(error);
    }
    NProgress.done();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "order",
      key: "id",
      width: "2%",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "order",
      key: "name",
      width: "20%",
      render: (text) => text.name,
    },
    {
      title: "Card Type",
      dataIndex: "order",
      key: "typeCard",
      width: "20%",
      // eslint-disable-next-line react/display-name
      render: (text) => (
        <div style={{ wordBreak: "break-word" }}>
          {cardTypes[text.typeCard]}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "createAt",
      key: "date",
      render: (text) => moment(text).format("HH:mm, DD MMM YYYY"),
      width: "20%",
    },
    {
      title: "History",
      dataIndex: "typePaid",
      key: "typePaid",
      render: (text) => (text ? "Paid" : "Unpaid"),
      width: "20%",
    },
  ];

  return (
    <div>
      <PageHeader title="ORDERS History" />
      {orders === null ? (
        <Skeleton size="large" width={600} active />
      ) : (
        <Table columns={columns} dataSource={orders} scroll={{ x: "100%" }} />
      )}
    </div>
  );
}
