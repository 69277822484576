import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
const cardTypes = [
  "",
  "Thẻ cơ bản",
  "Thẻ đen cơ bản",
  "Thẻ RGB đen",
  "Thẻ RGB màu",
  "Thẻ nametag Luxury",
  "Thẻ CLDxMetap",
  "Thẻ Geometric",
];
export default function OrderReport({ orders }) {
  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const cardPaid = payload[1].payload.cards.filter((f) => f.paid);
      const groupByCardType = cardPaid ? groupBy(cardPaid, "typeCard") : [];
      console.log(groupByCardType);

      return (
        <div className="custom-tooltip border border-gray bg-white p-2">
          <p className="label font-bold">{label}</p>
          {payload.map((item, idx) => {
            return (
              <div key={idx} style={{ color: item.fill }}>
                <span className="capitalize">{item.name}: </span>
                <span className="">{item.value}</span>
              </div>
            );
          })}
          {Object.keys(groupByCardType).length > 1 ? (
            <div className="line my-2"></div>
          ) : null}
          {Object.keys(groupByCardType).map((item, idx2) => {
            return (
              <div key={idx2} className="flex">
                <div style={{ width: 150 }}>{cardTypes[item]}: </div>
                <div>{groupByCardType[item].length}</div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <BarChart
        width={750}
        height={350}
        data={orders}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend margin={{ top: 200 }} height={1} />
        <Bar dataKey="orders" fill="#82ca9d" />
        <Bar dataKey="paid" fill="#8884d8" />
      </BarChart>
    </div>
  );
}
